<template>
  <footer>
    <cookie-law theme="medo">
      <v-row slot-scope="props">
        <v-col cols="12" xl="10" lg="9" md="8">
          <div slot="messagge">
            Auf unserer Seite nutzen wir lediglich Cookies um dir Youtube mit
            den gewohnten Features und somit für unser Werbevideo zur Verfügung
            zu stellen. Weitere Informationen findest du hier
            <router-link class="aCookie" to="/privacy-policy">Datenschutzerklärung</router-link>
          </div>
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4">
          <v-btn
            small
            color="green"
            right
            class="skew"
            @click="
              props.accept;
              updatePrivacyAccept();
            "
            style="margin-right: 5px"
          >
            Aktzeptieren
          </v-btn>
          <v-btn
            small
            color="grey"
            right
            shaped
            class="skew"
            @click="
              props.close;
              deactiveCookieBar();
            "
            style="margin-left: 5px"
          >
            Ignorieren
          </v-btn>
        </v-col>
      </v-row>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

export default {
  components: { CookieLaw },
  methods: {
    updatePrivacyAccept() {
      this.$store.commit('aggreePrivacyPolicy');
    },
    deactiveCookieBar() {
      this.$store.commit('cookieBarDeactivate');
    },
  },
};
</script>
<style>
.Cookie--medo {
  background:#9C0400;
  padding: 0rem 1.250rem;
  color: #fff;

}
.aCookie {
  color: rgb(11, 146, 209) !important;
  font-weight: bold !important;
}
</style>
