<template>
  <!-- light blue #1d4d9c -->
  <v-footer :color="footerBackground1">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" lg="3" md="4" sm="12">
          <v-card flat color="transparent">
            <v-card-title v-bind:style="{ color: fontColor }">
              Kontakt
            </v-card-title>
            <v-divider />
            <v-card-text v-bind:style="{ color: fontColor }">
              <v-row class="d-flex" align="center">
                <v-icon class="pa-2">mdi-cellphone</v-icon>
                <a
                  v-bind:style="{ color: fontColorLink }"
                  href="tel:01708267282"
                >
                  0170/8267282</a
                >
              </v-row>
              <v-row class="d-flex" align="center">
                <v-icon class="pa-2">mdi-phone-classic</v-icon>
                <a
                  v-bind:style="{ color: fontColorLink }"
                  href="tel:061288600169"
                >
                  06128/8600169</a
                >
              </v-row>
              <v-row class="d-flex" align="center">
                <v-icon class="pa-2">mdi-email-outline</v-icon>
                <a
                  v-bind:style="{ color: fontColorLink }"
                  href="mailto:info@medo-sanierung.de"
                >
                  info@medo-sanierung.de</a
                >
              </v-row>
              <v-row class="d-flex">
                <v-icon class="pa-2">mdi-map-marker</v-icon>
                <a v-bind:style="{ color: fontColorLink }">
                  Medo Sanierung <br />
                  Kantstr. 18 <br />
                  65232 Taunusstein <br />
                </a>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="4" sm="12">
          <v-card flat color="transparent">
            <v-card-title v-bind:style="{ color: fontColor }">
              Rechtliches
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-list
                min-width="100%"
                style="background-color: rgba(0, 0, 0, 0)"
              >
                <v-list-item
                  to="/legal-notice"
                  v-bind:style="{ color: fontColorLink }"
                >
                  Impressum
                </v-list-item>
                <v-list-item
                  to="/privacy-policy"
                  v-bind:style="{ color: fontColorLink }"
                >
                  Datenschutz
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="4" sm="12">
          <v-card flat color="transparent">
            <v-card-title v-bind:style="{ color: fontColor }">
              Social Media
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-btn icon href="https://www.instagram.com/medo.sanierung/" target="_blank">
                <v-icon large v-bind:style="{ color: fontColor }"
                  >mdi-instagram</v-icon
                >
              </v-btn>
              <v-btn icon href="https://www.youtube.com/channel/UC4ujDcGk2uIfXcjPyb0Xfdg/videos" target="_blank">
                <v-icon large v-bind:style="{ color: fontColor }"
                  >mdi-youtube</v-icon
                >
              </v-btn>
              <v-btn icon href="https://www.facebook.com/Medo.Sanierung" target="_blank">
                <v-icon large v-bind:style="{ color: fontColor }"
                  >mdi-facebook</v-icon
                >
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {},
  data: () => ({
    fontColor: 'white',
    fontColorLink: 'white !important',
    footerColorSwitch: false,
    footerBackground1: '#1d4d9c',
    footerBackground2: '#0433ff',
    //
  }),
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
