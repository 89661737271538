<template>
  <v-app>
    <Appbar />
    <v-main>
      <router-view />
    </v-main>
    <Footer id="footer" />
    <ScrollTop />
    <CookieLaw v-if="cookieBar && cookie==false"/>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Appbar from './components/Appbar.vue';
import Footer from './components/Footer.vue';
import ScrollTop from './components/ScrollTop.vue';
import CookieLaw from './components/cookies/Cookielaw.vue';

export default {
  name: 'App',

  components: {
    Appbar,
    Footer,
    ScrollTop,
    CookieLaw,
  },
  methods: {},
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      cookieBar: 'stateOfCookieBar',
      cookie: 'stateOfPrivacy',
    }),
  },
};
</script>
<style >
h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
}
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.5rem;
}
</style>
