<template>
  <div>
    <!-- <v-row justify="center">
      <v-col v-for="n in 12" :key="n" cols="12" sm="5" md="2" lg="1" style="margin: 10px">
        <v-sheet
          color="grey darken-2"
          elevation="10"
          height="150"
          width="150"
          :rounded="'circle'"
          ><v-container fill-height>
            <v-row align="center" justify="center"
              ><v-col>
                <p class="text-center" style="color: white">test</p>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row> -->
    <v-container
      class="d-flex justify-center align-content-space-around flex-wrap"
    >
      <v-sheet
        :style="{
          margin: '10px',
          backgroundSize: '200px 200px',
          backgroundImage: 'url(' + item.img + ')',
        }"
        v-for="(item, i) in value.data"
        :key="i"
        color="grey darken-2"
        elevation="10"
        max-height="200"
        max-width="200"
        min-height="200"
        min-width="200"
        :rounded="'circle'"
        ><v-container fill-height>
          <v-row align="center" justify="center"
            ><v-col class="text-center">
              <div
                class="rounded"
                :style="{ backgroundColor: value.backgroundColor }"
              >
                <h4 :style="{ color: value.textColor }">
                  {{ item.Name }}
                </h4>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'TilesOnlyButtonView',
  props: ['value'],
  methods: {},
  data: () => ({}),
};
</script>
