import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/home',
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../pages/Services.vue'),
  },
  {
    path: '/references',
    name: 'References',
    component: () => import('../views/References.vue'),
  },
  {
    path: '/legal-notice',
    name: 'LegalNotice',
    component: () => import('../pages/LegalNotice.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../pages/PrivacyPolicy.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  // #########################
  // reference pages
  // -------------------------
  {
    path: '/references/eintracht-kraftraum',
    name: 'references/eintracht-kraftraum',
    component: () => import('../pages/references/eintracht-kraftraum.vue'),
  },
  {
    path: '/references/bad-sanierung-taunusstein',
    name: 'references/bad-sanierung-taunusstein',
    component: () => import('../pages/references/bad-sanierung-taunusstein.vue'),
  },
  {
    path: '/references/brandschaden-bingen',
    name: 'references/brandschaden-bingen',
    component: () => import('../pages/references/brandschaden-bingen.vue'),
  },
  {
    path: '/references/brandschaden-wiesbaden',
    name: 'references/brandschaden-wiesbaden',
    component: () => import('../pages/references/brandschaden-wiesbaden.vue'),
  },
  {
    path: '/references/eintracht-treppenhaus',
    name: 'references/eintracht-treppenhaus',
    component: () => import('../pages/references/eintracht-treppenhaus.vue'),
  },
  {
    path: '/references/eintracht-fassade',
    name: 'references/eintracht-fassade',
    component: () => import('../pages/references/eintracht-fassade.vue'),
  },
  {
    path: '/references/eintracht-mauer',
    name: 'references/eintracht-mauer',
    component: () => import('../pages/references/eintracht-mauer.vue'),
  },
  {
    path: '/references/fassade-mainz-kostheim',
    name: 'references/fassade-mainz-kostheim',
    component: () => import('../pages/references/fassade-mainz-kostheim.vue'),
  },
  {
    path: '/references/restaurierung-wiesbaden',
    name: 'references/restaurierung-wiesbaden',
    component: () => import('../pages/references/restaurierung-wiesbaden.vue'),
  },
  {
    path: '/references/sanierung-taunusstein',
    name: 'references/sanierung-taunusstein',
    component: () => import('../pages/references/sanierung-taunusstein.vue'),
  },
  {
    path: '/references/wasserschaden-mainz-kastel',
    name: 'references/wasserschaden-mainz-kastel',
    component: () => import('../pages/references/wasserschaden-mainz-kastel.vue'),
  },
  {
    path: '/references/bad-heilbronn',
    name: 'references/bad-heilbronn',
    component: () => import('../pages/references/bad-heilbronn.vue'),
  },
  {
    path: '/references/balkon-mannheim',
    name: 'references/balkon-mannheim',
    component: () => import('../pages/references/balkon-mannheim.vue'),
  },

];

/* eslint-disable no-else-return */
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // https://stackoverflow.com/questions/42538449/vue-js-scroll-to-top-of-new-page-route-after-settimeout
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      // window.scrollTo(0, 0);
      return { x: 0, y: 0 };
    }
  },
});
/* eslint-enable no-else-return */
// router.beforeEach((to, from, next) => {
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
//   next();
// });

export default router;
