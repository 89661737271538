import Vue from 'vue';
import CookieLaw from 'vue-cookie-law';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;
Vue.use(CookieLaw);

new Vue({
  el: '#app',
  router,
  store,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  vuetify,
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  render: (h) => h(App),
}).$mount('#app');
