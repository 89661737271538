<template>
  <v-dialog
    v-model="overlay"
    persistent
    fullscreen
    transition="scroll-x-reverse-transition"
    ><v-container
      fluid
      style="background-color: rgba(46, 49, 49, 0.95); height: 100%"
    >
      <v-card color="rgb(46, 49, 49)" height="100%">
        <v-btn block text @click="close()">
          <v-spacer></v-spacer>
          <v-icon color="white"> mdi-close </v-icon>
        </v-btn>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-list min-width="100%" style="background-color: rgba(0, 0, 0, 0)">
            <v-list-item>
              <v-spacer></v-spacer>
              <v-btn text color="white" to="/" @click="close()">Home</v-btn>
            </v-list-item>
            <!--<v-list-item>
              <v-spacer></v-spacer>
              <v-btn text color="white" to="/services" @click="close()"
                >Leistungen</v-btn
              >
            </v-list-item>-->
            <v-list-item>
              <v-spacer></v-spacer>
              <v-btn text color="white" to="/references" @click="close()"
                >Referenzen</v-btn
              >
            </v-list-item>
            <v-list-item>
              <v-spacer></v-spacer>
              <v-btn text color="white" @click="close(); $vuetify.goTo('#footer')"
                >Kontakt</v-btn
              >
            </v-list-item>
            <!-- DIVIDER -->
            <v-divider style="margin: 15px 30px" dark></v-divider>
            <!-- DIVIDER -->
            <v-list-item>
              <v-spacer></v-spacer>
              <v-btn text color="white">
                <v-icon color="blue">mdi-cellphone</v-icon>
                <a style="color: white" href="tel:01708267282">
                0170/8267282
                </a>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-spacer></v-spacer>
              <v-btn text color="white">
                <v-icon color="blue">mdi-phone-classic</v-icon>
                <a style="color: white" href="tel:061288600169">
                 06128/8600169
                </a>
              </v-btn>
            </v-list-item>
            <!-- DIVIDER -->
            <v-divider style="margin: 15px 30px" dark></v-divider>
            <!-- DIVIDER -->
            <v-container>
            <v-list-item>
              <v-spacer></v-spacer>
              <h3 style="color: white">Social Media</h3>
            </v-list-item>
            <v-list-item>
              <v-spacer> </v-spacer>
              <v-btn icon href="https://www.instagram.com/medo.sanierung/" target="_blank">
                <v-icon large color="blue">
                  mdi-instagram</v-icon
                >
              </v-btn>
              <v-btn icon href="https://www.youtube.com/channel/UC4ujDcGk2uIfXcjPyb0Xfdg/videos" target="_blank">
                <v-icon large color="blue">
                  mdi-youtube</v-icon
                >
              </v-btn>
              <v-btn icon href="https://www.facebook.com/Medo.Sanierung" target="_blank">
                <v-icon large color="blue">
                  mdi-facebook</v-icon
                >
              </v-btn>
            </v-list-item>
            </v-container>
            <!--
            <v-list-item>
              <v-spacer></v-spacer>
              <v-icon color="blue">mdi-phone</v-icon>
              <a style="color: white" href="tel:01708267282"> 0170/8267282</a>
              <v-spacer></v-spacer>
            </v-list-item>
            <v-list-item>
              <v-spacer></v-spacer>
              <v-icon color="blue">mdi-email-outline</v-icon>
              <a style="color: white" href="mailto:medo2011@hotmail.de">
                medo2011@hotmail.de</a
              >
              <v-spacer></v-spacer>
            </v-list-item>
            <v-list-item>
              <v-spacer></v-spacer>
              <v-icon color="blue">mdi-google-maps</v-icon>

              <a style="color: white">
                Medo Sanierung <br />
                Edis Bogucanin <br />
                Kantstr. 18 <br />
                65232 Taunusstein <br />
              </a>
              <v-spacer></v-spacer>
            </v-list-item>
            -->
          </v-list>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'MobileNav',
  props: ['value'],
  methods: {
    close() {
      this.overlay = false;
      this.$emit('mobileNav', false);
    },
  },
  data: () => ({
    overlay: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.overlay = newVal;
      },
    },
  },
};
</script>
