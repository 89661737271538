import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    agreedToPrivacy: false,
    cookieBar: true,
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('cookie:accepted')) {
        state.agreedToPrivacy = true;
      }
    },
    aggreePrivacyPolicy(state) {
      localStorage.setItem('cookie:accepted', true);
      state.agreedToPrivacy = true;
    },
    cookieBarDeactivate(state) {
      state.cookieBar = false;
    },
    cookieBarActivate(state) {
      state.cookieBar = true;
    },
  },
  getters: {
    /* eslint-disable */
    stateOfPrivacy: (state) => { return state.agreedToPrivacy; },
    stateOfCookieBar: (state) => { return state.cookieBar; },
    /* eslint-enable */
  },
  actions: {
  },
  modules: {
  },
});
