<template>
  <div>
    <!--  <div
          style="
            height: 100%;
            color: black;
            font-family: Cookie;
            font-size: 20px;
            letter-spacing: 0.1em;
            transform: rotate(-30deg);
          "
        >
          <p
            class="text-center"
            style="
              text-align: center;
            "
          >
            Zufriedenheit bleibt!
          </p>
        </div> -->
    <!--       style="font-family: 'Gilroy,Helvetica,sans-serif'"
      1.125em;
      line-height:
      2rem;
      letter-spacing:
      -.00875em;-->
    <!-- <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col> -->
    <!--<v-row justify="center">
      <v-col
        class="gradient-multiline"
        cols="12"
        sm="12"
        align-self="center"
        style="text-align: center"
      >
        <h2>
          <span>Zufriedenheit, die bleibt!</span>
        </h2>
      </v-col>
    </v-row>-->
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          xl="4"
          lg="6"
          md="6"
          style="max-height: 300px; margin-bottom: 20px"
        >
          <!--                   cycle         interval="5000" -->
          <div style="position: relative">
            <v-card class="mx-auto"> </v-card>
            <v-carousel
              height="300px"
              cycle
              interval="5000"
              progress
              hide-delimiter-background
              delimiter-icon="mdi-minus"
              progress-color="red"
              style="top: 0; left: 0; z-index: 1"
            >
              <v-carousel-item>
                <v-img
                  src="@/assets/medo-sanierung_home.png"
                  height="300px"
                  style="
                    box-shadow: 1.04375em 1.04375em 0.9625em 0
                      rgba(0, 0, 0, 0.4);
                  "
                >
                </v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img
                  src="@/assets/medo-sanierung-home-finish.png"
                  height="300px"
                  style="
                    box-shadow: 1.04375em 1.04375em 0.9625em 0
                      rgba(0, 0, 0, 0.4);
                  "
                >
                  <v-card-actions
                    style="height: 100%; width: 100%"
                    class="justify-center"
                  >
                    <div
                      style="
                        color: white;
                        display: table;
                        font-family: Cookie;
                        font-size: 25px;
                        letter-spacing: 0.1em;
                        transform: rotate(-25deg);
                        background-color: rgba(0, 0, 0, 0.5);
                        border-radius: 25px;
                        padding: 5px 10px;
                      "
                    >
                      <!-- #0433ff
                    text-shadow: 0px 0px 3px white;
                     -->
                      <span
                        class="text-center"
                        style="
                          text-align: center;
                          vertical-align: middle;
                          display: table-cell;
                        "
                      >
                        Zufriedenheit bleibt!
                      </span>
                    </div>
                  </v-card-actions>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
        <v-col cols="12" xl="4" lg="6" md="6" align-self="center">
          <h1>
            Dein Zuhause.
            <br />Deine Entscheidung.
          </h1>
          <p style="font">
            Wir, die Firma Medo Sanierung, arbeiten vollumfänglich in dem
            Bereich der Bausanierung, Renovierung, Modernisierung und
            Restaurierung.
            <br />Über die Jahre konnten wir uns durch unser Hauptaugenmerk von
            Kundenzufriedenheit, Qualität und Zuverlässigkeit zu einem gefragten
            Einzelunternehmen in dieser Branche vorarbeiten. <br />
            <br />
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-divider style="margin: 40px 80px" light></v-divider>
    <v-container fluid style="background-color: #e4e4e4">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" xl="4" lg="6" md="6">
            <h1>
              Dein Vertrauen.
              <br />Unser Versprechen.
            </h1>
            <p>
              Getreu unserer Mottos “Ihr Zuhause in guten Händen!” und
              “Zufriedenheit, die bleibt”, haben wir unsere Geschäftsfelder
              stetig ausgebaut, um Ihnen ein großes Leistungsangebot in unseren
              Geschäftsbereichen anbieten zu können.
              <br />
              Auch unser Team wurde kontinuierlich erweitert, damit wir viele
              verschiedene Aufgaben und Herausforderungen stets zur vollen
              Zufriedenheit des Kunden meistern können.
            </p>
          </v-col>
          <v-col cols="12" xl="4" lg="6" md="6">
            <!-- Wenn keine Cookies zugelassen sind -->
            <v-img
              v-if="cookie == false"
              :src="'https://i.ytimg.com/vi/' + video + '/hqdefault.jpg'"
              gradient="to top right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)"
              height="300px"
            >
              <v-container
                style="max-height: 25%; padding: 25px"
                class="justify-center"
              >
                <p class="text-center" style="color: white">
                  <v-icon class="pa-2" color="white"
                    >mdi-alert-circle-outline</v-icon
                  >
                  zum abspielen dieses Videos werden Cookies benötigt, bitte
                  Einstellung anpasssen
                </p>
              </v-container>
              <v-container
                style="max-height: 50%; width: 100%; margin-top: 50px"
              >
                <v-card-actions class="justify-center">
                  <v-btn
                    @click="activateCookieBar()"
                    x-large
                    rounded
                    color="darkgrey"
                    style="background-color: rgba(0, 0, 0, 0.5)"
                    ><span style="color: white">Einstellung ändern</span></v-btn
                  >
                </v-card-actions>
              </v-container>
              <v-img
                style="position: absolute; bottom: 0; width: 100%"
                src="@/assets/Youtubebar.png"
              />
            </v-img>
            <!--</cookie-law>-->
            <!-- Wenn Cookies zugelassen sind -->
            <Youtube
              v-if="cookie"
              v-model="video"
              style="
                box-shadow: 1.04375em 1.04375em 0.9625em 0 rgba(0, 0, 0, 0.4);
              "
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-divider style="margin: 40px 80px" light></v-divider>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          xl="4"
          lg="6"
          md="6"
          style="max-height: 300px; margin-bottom: 20px"
          order="2"
          order-md="1"
        >
          <!--######### Picture with Button Code Snippet Referenzen_Medo.jpeg######## -->
          <v-card class="mx-auto" justify-center flat>
            <v-img
              src="@/assets/Referenzen_Medo.jpg"
              height="300px"
              style="
                box-shadow: 1.04375em 1.04375em 0.9625em 0 rgba(0, 0, 0, 0.4);
              "
            >
              <v-card-actions
                style="height: 100%; width: 100%"
                class="justify-center"
              >
                <v-btn
                  x-large
                  rounded
                  color="darkgrey"
                  to="/references"
                  style="background-color: rgba(0, 0, 0, 0.5)"
                  ><span style="color: white">Referenzen</span></v-btn
                >
              </v-card-actions>
            </v-img>
          </v-card>
        </v-col>
        <v-col order="1" order-md="2" cols="12" xl="4" lg="6" md="6">
          <h1>
            Deine Ideen.
            <br />Unsere Erfahrung.
          </h1>
          <p>
            Seit 2012 bieten wir bereits unsere Leistungen an.
            <br />Bei Firmengründung noch ausschließlich im Schreiner- und
            Parkettlegerbereich tätig, können wir nun nach
            {{ new Date().getFullYear() - 2012 }} erfolgreichen Jahren in der
            Branche ein großes und vielfältiges Leistungsangebot vorweisen.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-divider style="margin: 40px 80px" light></v-divider>
    <v-container fluid style="background-color: #e4e4e4">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="12" style="text-align: center">
            <h1>Unsere Leistungen</h1>
          </v-col>
        </v-row>
        <div :style="$vuetify.breakpoint.xlOnly ? 'padding: 0px 300px' : ''">
          <TilesOnlyButtonView v-model="json" id="services" />
        </div>
      </v-container>
    </v-container>
    <v-divider style="margin: 40px 80px" light></v-divider>
    <v-container fluid>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="12" style="text-align: center">
            <h1>Unsere Partner</h1>
          </v-col>
          <v-divider />
          <v-col
            cols="12"
            xl="4"
            lg="6"
            md="6"
            style="max-height: 100%; margin-bottom: 20px"
          >
            <v-card class="mx-auto" style="box-shadow: none">
              <!-- <v-img
                v-if="this.$vuetify.theme.isDark==false"
                src="@/assets/eintracht-logo-with-text.png"
                contain
                max-height="100px"
                max-width="320"
                style="margin: auto"
              />
              <v-img
                v-if="this.$vuetify.theme.isDark"
                src="@/assets/eintracht-logo-with-text-white.png"
                contain
                max-height="100px"
                max-width="320"
                style="margin: auto"
              />  -->
              <v-img
                :src="
                  this.$vuetify.theme.isDark
                    ? require('@/assets/eintracht-logo-with-text-white.png')
                    : require('@/assets/eintracht-logo-with-text.png')
                "
                contain
                max-height="100px"
                max-width="320"
                style="margin: auto"
              />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="6"
            md="6"
            style="max-height: 100%; margin-bottom: 20px"
          >
            <v-card class="mx-auto" style="box-shadow: none">
              <v-img
                src="@/assets/brillux-logo-with-text.png"
                contain
                height="100px"
                max-width="200"
                style="margin: auto"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-divider style="margin: 40px 80px" light></v-divider>
    <v-container fluid style="background-color: #e4e4e4">
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            xl="4"
            lg="6"
            md="6"
            style="max-height: 300px; margin-bottom: 20px"
            order="2"
            order-md="1"
          >
            <!--######### Picture with Button Code Snippet Referenzen_Medo.jpeg######## -->
            <v-card class="mx-auto" justify-center flat>
              <v-img
                src="@/assets/Edis_2.jpg"
                height="300px"
                style="
                  box-shadow: 1.04375em 1.04375em 0.9625em 0 rgba(0, 0, 0, 0.4);
                "
              >
                <!--  <span
                  style="
                    color: white;
                    font-weight: bold;
                    margin-right: 0;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(38, 12, 12, 0.5);
                  "
                  >Inh. Edis Bogucanin</span
                > -->
              </v-img>
            </v-card>
          </v-col>
          <v-col order="1" order-md="2" cols="12" xl="4" lg="6" md="6">
            <h2>Inh. Edis Bogucanin</h2>
            <!-- h3>Edis Bogucanin</h3> -->
            <p>
              Schon in jungen Jahren seit 2004 im Handwerk tätig. Als gelernter
              Schreiner konnte ich mein Arbeitsspektrum in der Baubranche stetig
              erweitern. Unter einigen Erfahrungen, die meinen Berufsweg positiv
              beeinflussen konnten, zählt auch der Besuch einer Meisterschule in
              Mainz. Mit großer Unterstützung und besonderen Fachkenntnissen
              meines Bruders, der an meiner Seite steht, sehe ich mich nach 17
              Jahren als kompetenten und seriösen Einzelunternehmer, der für
              seine Kundschaft mit Rat und Tat gerne zur Verfügung steht.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Youtube from '@/components/Youtube.vue';
import TilesOnlyButtonView from '@/components/TilesOnlyButtonView.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HelloWorld',
  components: {
    Youtube,
    TilesOnlyButtonView,
  },
  data: () => ({
    video: 'Ksd2Kqt4pgY',
    json: {
      textColor: 'white',
      backgroundColor: 'rgb(51, 51, 51, 0.7)',
      data: {
        0: {
          Name: 'Parkett- und Bodenverlegung',
          img: '/img/services/ParkettuBodenverlegung.jpg',
        },
        1: {
          Name: 'Einbau von genormten Baufertigteilen',
          img: '/img/services/EinbauvongenormtenBaufertigteilen.jpg',
        },
        2: {
          Name: 'Fliesenarbeiten',
          img: '/img/services/Fliesenarbeiten.jpg',
        },
        3: {
          Name: 'Trockenbau',
          img: '/img/services/Trockenbau.jpg',
        },
        4: {
          Name: 'Maurerarbeiten',
          img: '/img/services/Mauerarbeiten.jpg',
        },
        5: {
          Name: 'Verputzarbeiten',
          img: '/img/services/Spachtelarbeiten.jpg',
        },
        6: {
          Name: 'Malerarbeiten',
          img: '/img/services/Malerarbeiten.jpg',
        },
        7: {
          Name: 'Bautrocknung',
          img: '/img/services/Bautrocknung.jpg',
        },
        8: {
          Name: 'Bausanierung',
          img: '/img/services/Bausanierung.jpg',
        },
        9: {
          Name: 'Fassadenarbeiten',
          img: '/img/services/Fassadenarbeiten.jpg',
        },
        10: {
          Name: 'Sonderanfertigungen und Schreinerarbeiten',
          img: '/img/services/Sonderanfertigunge&Schreinerarbeiten.jpg',
        },
        11: {
          Name: 'Bäder planen und gestalten',
          img: '/img/services/BaederPlanenUndGestalten.jpg',
        },
        12: {
          Name: 'Arbeiten an Denkmalschutz',
          img: '/img/services/ArbeitenAnDenkmalschutz.jpg',
        },
        13: {
          Name: 'Estricharbeiten',
          img: '/img/services/Estrich.jpg',
        },
        14: {
          Name: 'Abbrucharbeiten',
          img: '/img/services/Abbrucharbeiten.jpg',
        },
      },
    },
    //
  }),
  methods: {
    activateCookieBar() {
      this.$store.commit('cookieBarActivate');
      // console.log(this.$vuetify.theme.themes.light);
      // console.log(this.$vuetify.theme.isDark);
    },
  },
  computed: {
    ...mapGetters({
      cookie: 'stateOfPrivacy',
    }),
  },
};
</script>

<style>
h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
}
.gradient-multiline {
  color: white;
  background-image: linear-gradient(to right, #9c0400, #ffd5d4);
  padding: 0.225rem 0.5rem;
}
</style>
