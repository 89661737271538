<template>
  <div>
    <div v-if="video != null" class="youtube-container-100">
      <iframe
        :src="video"
        width="100%"
        height="100%"
        allowfullscreen="allowfullscreen"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Youtube',
  props: ['value'],
  data: () => ({
    video: null,
    //
  }),
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.video = 'https://www.youtube-nocookie.com/embed/' + newVal + '?rel=0&modestbranding=1';
      },
    },
  },
};
</script>

<style>
.youtube-container-100 {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 0;
  height: 0;
  overflow: hidden;
}
.youtube-container-100 iframe,
.youtube-container-100 embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
