<template>
  <v-app-bar
    hide-on-scroll
    app
    height="100"
    color="rgba(0, 0, 0, 0)"
    elevation="0"
  >
    <v-app-bar dark app color="#9C0400" min-height="64">
      <div style="height: 100%">
        <router-link to="/home">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="../assets/medo-logo-xl.png"
            transition="scale-transition"
            width="150"
            max-height="100"
            style="box-shadow: 0 0 10px #58595c"
          />
        </router-link>
      </div>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text color="white" to="/home">Home</v-btn>
        <!--<v-btn text color="white"
      @click="$vuetify.goTo('#services');this.$router.push({path: '/home'})">Leistungen
      </v-btn> -->
        <v-btn text color="white" to="/references">Referenzen</v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!--      style="color:blue; height:100%; line-height: 100%; text-align: center;"  -->
       <!-- <div
          style="
            height: 100%;
            display: table;
            margin-top: 20px;
            color: black;
            font-family: Cookie;
            font-size: 20px;
            letter-spacing: 0.1em;
            transform: rotate(-30deg);
          "
        >
          <p
            class="text-center"
            style="
              text-align: center;
              vertical-align: middle;
              display: table-cell;
            "
          >
            Zufriedenheit bleibt!
          </p>
        </div> -->
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text color="white" @click="$vuetify.goTo('#footer')"
          >Kontakt</v-btn
        >
        <v-divider vertical light />
        <v-btn text color="white">
          <v-icon>mdi-phone</v-icon>
          <a style="color: white" href="tel:0170 8267282"> 0170/8267282 </a>
        </v-btn>
        <!-- <v-btn text color="white" to="/4">textRight2</v-btn> -->
      </v-toolbar-items>
      <MobileNav
        class="hidden-md-and-up"
        v-model="mobileNav"
        @mobileNav="mobileNav = false"
      />
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="mobileNav = true">
      </v-app-bar-nav-icon>
    </v-app-bar>
  </v-app-bar>
</template>

<script>
import MobileNav from '@/components/appbar/MobileNav.vue';

export default {
  name: 'Appbar',
  components: {
    MobileNav,
  },
  methods: {},
  data: () => ({
    mobileNav: false,
    //
  }),
};
</script>

<style>
.v-btn.v-size--default {
  font-size: 1rem;
}
</style>
